import {html, LitElement} from 'lit'
import {customElement, property, state} from 'lit/decorators.js'
import {i18nService} from '../../services/i18nService';
import style from "./style.scss?inline";
import OnStateChangeEvent = YT.OnStateChangeEvent;
import PlayerEvent = YT.PlayerEvent;

declare global {
  interface HTMLElementTagNameMap {
    'youtube-player': YoutubePlayer
  }

  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}

const Icons = {
  PLAY: 'M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26',
  PAUSE: 'M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28',
  REPLAY: 'M 16.144531 11.222656 C 15.566406 14.089844 13.023438 16.171875 10.097656 ' +
    '16.171875 C 6.695312 16.171875 3.925781 13.402344 3.925781 9.996094 C 3.925781 ' +
    '6.59375 6.691406 3.824219 10.097656 3.824219 C 11.71875 3.824219 13.253906 4.480469 ' +
    '14.402344 5.597656 L 12.496094 7.523438 L 18.773438 7.523438 L 18.773438 1.21875 L ' +
    '17.042969 2.945312 C 15.195312 1.128906 12.71875 0.101562 10.101562 0.101562 C ' +
    '4.640625 0.101562 0.199219 4.542969 0.199219 9.996094 C 0.199219 15.457031 4.640625 ' +
    '19.894531 10.097656 19.894531 C 14.792969 19.898438 18.875 16.558594 19.800781 ' +
    '11.960938 Z M 16.144531 11.222656'
};

@customElement('youtube-player')
export class YoutubePlayer extends LitElement {
  @property() public url = ''
  @property() public customControl = false;

  @state() progress = 0;
  @state() playerState: YT.PlayerState = -1;
  @state() i18nInitialized: boolean = false

  player: YT.Player | undefined;
  playerId = "embed-player" + this.guid();
  interval: number | undefined;

  constructor() {
    super();
    this.initPlayer = this.initPlayer.bind(this);
    this.updateProgressBar = this.updateProgressBar.bind(this);
  }

  async connectedCallback() {
    super.connectedCallback();
    await i18nService.initTranslations();
    this.i18nInitialized = true;
    this.loadPlayer();
  }

  render() {
    if (!this.i18nInitialized) return;

    return html`
      <style>${style}</style>
      <div class="youtube-player">
        ${this.renderPlayer()}
      </div>
    `
  }

  loadPlayer() {
    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";
    this.renderRoot.appendChild(script);

    window.onYouTubeIframeAPIReady = this.initPlayer;
  }

  initPlayer() {
    new YT.Player(this.playerId, {
      events: {
        'onStateChange': (event: OnStateChangeEvent) => {
          this.playerState = event.data;

          if (event.data == YT.PlayerState.PLAYING) {
            this.interval = setInterval(this.updateProgressBar, 300);
          } else {
            clearInterval(this.interval);
          }
        },
        'onReady': (event: PlayerEvent) => {
          this.player = event.target;
          this.player.stopVideo();
          this.player.playVideo();
        }
      }
    });
  }


  renderPlayer() {
    if (this.customControl) {
      return html`
        <iframe id="${this.playerId}" src="${this.url}&amp;controls=0&amp;enablejsapi=1&amp;html5=1"
                allow="autoplay; encrypted-media" allowFullScreen></iframe>
        <div class="controls">
          ${this.renderActionButton()}
          ${this.renderProgressBar()}
        </div>
      `
    }
    return html`
      <iframe frameborder="0" src="${this.url}" allow="autoplay; encrypted-media" allowFullScreen></iframe>
    `
  }

  renderActionButton() {
    return html`
      <svg class="play-button-control"
           @click="${() => this.handleVideoAction(this.playerState)}">
        <path d="${this.getIcon(this.playerState)}"/>
      </svg>
    `
  }

  renderProgressBar() {
    return html`
      <div class="progress-bar-container">
        <div class="progress-bar">
          <div class="progress-bar-inner" style="width: ${this.progress}%"/>
        </div>
      </div>
      <input type="range" min="0" class="progress-bar" max="100" id="progress-bar-${this.playerId}"
             value="${this.progress}" @change=${this.handleClick}/>
    `
  }

  handleVideoAction(state: number) {
    if (!state || !this.player) return;

    if (this.playerState === YT.PlayerState.PLAYING) {
      this.player.pauseVideo();
      this.playerState = YT.PlayerState.PAUSED;
    } else if (this.playerState === YT.PlayerState.PAUSED) {
      this.player.playVideo();
      this.playerState = YT.PlayerState.PLAYING;
    } else if (this.playerState === YT.PlayerState.ENDED) {
      this.player.playVideo();
      this.playerState = YT.PlayerState.PLAYING;
    }
  }

  getIcon(state: number) {
    if (state === 1) return Icons.PLAY;
    if (state === 0) return Icons.REPLAY;
    return Icons.PAUSE;
  }

  handleClick(event: Event) {
    if (!this.player) return;
    const newTime = this.player.getDuration() * ((event.target as HTMLInputElement).valueAsNumber / 100);
    this.player.seekTo(newTime, true);
  }

  updateProgressBar() {
    if (!this.player) return;
    this.progress = (this.player.getCurrentTime() / this.player.getDuration()) * 100;
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}
